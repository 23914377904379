import "./App.css";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";
import Navbar from "./Components/Navbar";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";
import Fivetest from "./Components/Fivetest";
import Previousprompts from "./Components/Previousprompts";
import Allsubmission from "./Components/Allsubmission";
import DownloadRejected from "./Components/DownloadRejected";
import Deletefile from "./Components/Deletefile";
import Uploadfile from "./Components/Uploadfile";
import Fileupload from "./Components/Fileupload";
import Tokencalmulti from "./Components/Tokencalmulti";
import Tokencalbatchwise from "./Components/Tokencalbatchwise";
import Finalize from "./Components/Finalize";
import Home from "./Components/Home";

import { BrowserRouter, Route, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Provider store={store}>
       <BrowserRouter>
        <Navbar />
        
       
        <ReactNotifications />
       
        <RenderOnAuthenticated> 
          <Routes>
          
            <Route exact path="/" element={<Home />} />
            <Route path="/fivetest" element={<Fivetest />} />
            <Route path="/allsubmission" element={<Allsubmission />} />
         
            <Route path="/previousprompts" element={<Previousprompts />} />
            <Route path="/deletefile" element={<Deletefile />} />
            <Route path="/uploadfile" element={<Uploadfile />} />
            <Route path="/fileupload" element={<Fileupload />} />{" "}
            <Route path="/tokencalmulti" element={<Tokencalmulti />} />{" "}
            <Route path="/tokencalbatchwise" element={<Tokencalbatchwise />} />{" "}
            <Route path="/finalize" element={<Finalize />} />

               <Route path="/downloadrejectedfiles" element={<DownloadRejected />} />
          </Routes> 
        </RenderOnAuthenticated>
        <RenderOnAnonymous>
          <Routes>
          
          <Route exact path="/" element={<Home />} />
            <Route path="/fivetest" element={<Fivetest />} />
            <Route path="/allsubmission" element={<Allsubmission />} />
         
            <Route path="/previousprompts" element={<Previousprompts />} />
            <Route path="/deletefile" element={<Deletefile />} />
            <Route path="/uploadfile" element={<Uploadfile />} />
            <Route path="/fileupload" element={<Fileupload />} />{" "}
            <Route path="/tokencalmulti" element={<Tokencalmulti />} />{" "}
            <Route path="/tokencalbatchwise" element={<Tokencalbatchwise />} />{" "}
            <Route path="/finalize" element={<Finalize />} />
               <Route path="/downloadrejectedfiles" element={<DownloadRejected />} />
          </Routes> 
           </RenderOnAnonymous>
        </BrowserRouter>
      
      </Provider>
    </div>
  );
}

export default App;
