import React, { useEffect, useState } from "react";
import Navbar2 from "./Navbar";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Sidenav from "./Sidenav";

import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import UserService from "../services/UserService";
import getNotification from "../Redux/Action/action";
import Select from "react-select";
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_REDIRECT_URI } = process.env;
const {REACT_APP_API_KEY}=process.env;

function Uploadfile() {
  const [courseidlist, setcourseidlist] = useState([]);
  const [batchidlist, setbatchidlist] = useState([]);
  const [topicidlist, settopicidlist] = useState([]);
  const [courseId, setcourseId] = useState();
  // const [question, setquestion] = useState("Can Jallianwala Bagh Massacre be considered as a turning point in our freedom struggle? Give your reasons. ");
  // const [modelAnswer, setmodelAnswer] = useState("A. Does the submission explain the facts related to the jalianwala bagh massacre, the gravity and the emotional impact it had on the people? B. Does the submission explain that the massacre led to disbelief in colonialism. Mistrust in British rule and brought to light that evil inside the British empire? C. Does the submissions mention the beginning of non-cooperation movement, Khilafat movement, civil disobedience and quit india movement? D. Does the submission mention prominent leaders who returned their awards in protest? E. Does the submission mention that the massacre united people and leaders against the Biritsh rule of law? F. Does the submissions explain change in the attitude of Punjab state towards the revolution? G. Does the submission explain the second wave of revolutionary nationalism and mention key leaders like Bhagat singh, Chandrashekhar azad? H. Does the submission explain that it was the change in the attitude of people who actually affected and led the change? I. Is the submission contained within the limit of 150 words?");
  // const [studentAnswer, setstudentAnswer] = useState("Jalianwala Bagh massacre is one of the bloodiest chapters of the Indian National Movement on 13 April 1919, several people (including women and children) were killed at the orders of General Dyre.  What had happened –  People had gathered to celebrate the festival of Baisakhi There were also peaceful discussions on the arrest of two prominent leaders of Punjab -Lala Lajpat rai and Saifuddin Kitchlu. People were also protesting against the oppressive “Rowlett Act” when they were surrounded and shot. Jalianwala Bagh turned out to be a watershed movement in the freedom struggle. Prominent leaders giving up titles topicIdore gave up knighthood Gandhi returned his Kaser-i-hind in protest of the killing. Many who thought negotiations could work, gave up hopes on British Increased mass support/revolts Many mass support movements, demonstrations, dharnas were organized to mobilize people. Emergence of new leaders Like Lala Lajpat Rai, Bhagat Singh got heavily influenced People coming together against British against British oppression (Hindu Muslim unity), Khilafat movement, Non-cooperation     Jalianwala Bagh massacre was an event that turned even the British sympathizers against them. This event marked radical shift in how movements were to be carried out. People got agitated and continuous revolts against British after this. ");
  const [batchId, setbatchId] = useState();
  const [loader, setLoader] = useState(false);
  const [topicId, settopicId] = useState();
  const [subid, setsubid] = useState();
  const [excercise_question_list, setexcercise_question_list] = useState("");
  const [folderadd, setfolderadd] = useState("");
  const [file, setfile] = useState("");
  const [coursebatchexam, setcoursebatchexam] = useState("");
  const [filename, setfilename] = useState("");
  const [exercisequestionarray, setexercisequestionarray] = useState([]);
  const [nooftokens, setnooftokens] = useState(0);
  const [gptmodel, setgptmodel] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      fetchcoursebatch();
    } else {
      dispatch(
        getNotification({
          message: "Login has expired.Please login!",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1500);
    }
  }, []);
  const handlebatchId = (selection) => {
    if (UserService.isLoggedIn()) {
      setbatchId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };
  const handlecourseId = (selection) => {
    if (UserService.isLoggedIn()) {
      //console.log(value)
      setcourseId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };
  const handletopicId = (selection) => {
    if (UserService.isLoggedIn()) {
      settopicId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };
  const handlesubid = (e) => {
    if (UserService.isLoggedIn()) {
      setsubid(e.target.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };
  const handlegptmodel = (type) => {
    if (UserService.isLoggedIn()) {
      setgptmodel(type);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };
  const downloadsubmissiondetails = async () => {
    //console.log("d00")
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        
        sendData.append("api_key",REACT_APP_API_KEY );

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/download_submission_details/`,
          responseType: "blob",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          data: sendData,
        };

        const getResponse = await axios(config);

        if (getResponse.status === 200) {
          const blob = new Blob([getResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "submissiondetails.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (getResponse.status === 422) {
          dispatch(
            getNotification({
              message: "The source entered is not in prescribed format",
              type: "danger",
            })
          );
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is down.Please try after sometime",
              type: "danger",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
  };

  const handlefile = (e) => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput.files.length > 0) {
      setfile(fileInput.files[0]);
      setfilename(fileInput.files[0].name);
    }
  };
  const handlefolder = (type) => {
    setfolderadd(type);
  };
  const handlenooftokens = (e) => {
    setnooftokens(e.target.value);
  };
  const fetchcoursebatch = async (e) => {
    try {
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_course_and_batchname`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const getResponse = await axios(config);

      if (getResponse.status === 200) {
        if (getResponse.data === null) {
          dispatch(
            getNotification({
              message: "Files are not added yet by Lawsikho",
              type: "default",
            })
          );
        } else {
          setcoursebatchexam(getResponse.data);
          setcourseidlist(
            getResponse.data.course_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          setbatchidlist(
            getResponse.data.batch_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          settopicidlist(
            getResponse.data.topic_ids.map((it) => {
              return { value: it, label: it };
            })
          );
        }
        //setResponse(getResponse.data.result);
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };
  const handleaddfileinput = async () => {
    if (UserService.isLoggedIn()) {
      if (batchId && courseId && topicId && subid && folderadd && file) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("file", file);
          
          sendData.append("api_key",REACT_APP_API_KEY );
          sendData.append("course_id", courseId);
          sendData.append("batch_id", batchId);
          sendData.append("topic_id", topicId);
          sendData.append("sub_id", subid);
          var config = {};

          config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/add_input_file/`,
            // responseType:"application/json",
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            data: sendData,
          };

          setLoader(true);
          const getResponse = await axios(config);

          if (getResponse.status === 200) {
            if (getResponse.data) {
              alert("File is added to the Server! ");
            }
          } else if (getResponse.status === 422) {
            dispatch(
              getNotification({
                message: "The source entered is not in prescribed format",
                type: "danger",
              })
            );
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is down.Please try after sometime",
                type: "danger",
              })
            );
          }
        }
      } else {
        alert("Please fill all mandatory fields");
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
    //console.log("d00")

    setLoader(false);
  };
  const handleaddfileoutput = async () => {
    if (UserService.isLoggedIn()) {
      if (
        batchId &&
        courseId &&
        topicId &&
        subid &&
        folderadd &&
        file &&
        gptmodel &&
        nooftokens
      ) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("file", file);
          
          sendData.append("api_key",REACT_APP_API_KEY );
          sendData.append("course_id", courseId);
          sendData.append("batch_id", batchId);
          sendData.append("topic_id", topicId);
          sendData.append("sub_id", subid);
          sendData.append("number_of_tokens", nooftokens);
          sendData.append("gpt", gptmodel);

          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/add_output_file/`,
            // responseType:"application/json",
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            data: sendData,
          };

          setLoader(true);
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.status === 200) {
            if (getResponse.data.excercise_question_list) {
              setexcercise_question_list(
                getResponse.data.excercise_question_list
              );
              getResponse.data.excercise_question_list.map((item) => {
                handlequestionexercise(item);
              });
              alert("File is being uploaded.Please fill Score and Feedback for each question in below screen to complete upload.")
            }
          } else if (getResponse.status === 422) {
            dispatch(
              getNotification({
                message: "The source entered is not in prescribed format",
                type: "danger",
              })
            );
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is down.Please try after sometime",
                type: "danger",
              })
            );
          }
        }
      } else {
        console.log(gptmodel, nooftokens);
        alert("Please fill all mandatory fields");
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/uploadfile`,
        });
      }, 1000);
    }
    //console.log("d00")

    setLoader(false);
  };
  const addoutputscore = async () => {
    if (batchId && courseId && topicId && subid && exercisequestionarray) {
      try {
        setLoader(true);
        //const FormData = require("form-data");
        //const sendData = new FormData();
        
        const obj = {
          request: {
            api_key: REACT_APP_API_KEY,
            course_id: courseId,
            batch_id: batchId,
            topic_id: topicId,
            sub_id: subid,
            file_name: filename,
          },
          score_feedback: exercisequestionarray,
        };
        var obj2 = JSON.stringify(obj);

        const apiUrl = `${REACT_APP_UPLOAD_DATA}/add_output_score`;
        const getResponse = await axios.post(apiUrl, obj2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (getResponse.status === 200) {
          alert("Feedback and score added to Database");
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      alert("Please fill all mandatory fields");
    }
    setLoader(false);
  };
  const handlequestionexercise = (item) => {
    for (let i = 0; i < item.number_of_questions; i++) {
      let obj = {};
      obj.exercise = `Exercise ${item.exercise_number}`;
      obj.question = `Question ${i + 1}`;
      exercisequestionarray.push(obj);
    }
  };
  const renamescore = (e, item) => {
    item.scores = e.target.value;
  };
  const renamefeedback = (e, item) => {
    item.feedback = e.target.value;
  };
  return (
    <div className=" pageback">
      <div className="" id="main">
        <div className="row ">
          <div className="col-sm-2 sidenav">
            <Sidenav />
          </div>
          <div className=" col-sm-10 page  p-5" id="input">
            <div>
              {" "}
              <button
                className="btn btn-success btn-sm"
                onClick={() => downloadsubmissiondetails()}
              >
                Download Submission Details
              </button>
             
            </div>

            <h4 className="text-center" style={{ color: "green" }}>
              Upload Files
            </h4>
            <div className="my-3">  <small className="">Disclaimer: Currently,You are referring to Beta version of this screen. In case of any enquiries, Please contact Aneesha or Aayushi! </small>
           </div>
             
            {loader ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={{ height: "77vh" }}
              >
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Please wait while we fetch your results.."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            ) : (
              <div className="">
                <div className="form-group lef">
                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please attach your file{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    accept=".docx"
                    className="form-control  mb-3"
                    id="fileInput"
                    onChange={(e) => handlefile(e)}
                    aria-describedby="emailHelp"
                    placeholder="Please attach your file"
                  />
                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Course Id{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      id="courseId"
                      onChange={(value) => handlecourseId(value)}
                      options={courseidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please Enter Course ID"
                      value = {
                        courseidlist.filter(option => 
                           option.value === courseId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Course Id
                      </option>
                      {coursebatchexam.course_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Batch Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="  mb-3"
                      onChange={(value) => handlebatchId(value)}
                      options={batchidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please input Batch Id "
                      value = {
                        batchidlist.filter(option => 
                           option.value === batchId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Batch Id
                      </option>
                      {coursebatchexam.batch_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Topic Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      options={topicidlist}
                      onChange={(value) => handletopicId(value)}
                      aria-describedby="emailHelp"
                      placeholder="Please input Topic Id "
                      value = {
                       topicidlist.filter(option => 
                           option.value === topicId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Topic Id
                      </option>
                      {coursebatchexam.topic_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>
                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Sub Id <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control  mb-3"
                    value={subid}
                    onChange={(e) => handlesubid(e)}
                    aria-describedby="emailHelp"
                    placeholder="Please Enter Sub ID"
                  />
                </div>
                <label htmlFor="textarea">
                  Target Folder <span className="text-danger">*</span>
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={() => handlefolder("Input Folder")}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Input Folder
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={() => handlefolder("Output Folder")}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Output Folder
                  </label>
                </div>
                {folderadd === "Output Folder" && (
                  <div className="">
                    <label className="form-check-label" for="flexRadioDefault1">
                      No of Tokens
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3 mt-2 "
                      value={nooftokens}
                      placeholder="Please Enter No of Tokens "
                      onChange={(e) => handlenooftokens(e)}
                    />
                    <label htmlFor="textarea">
                      Model Type <span className="text-danger">*</span>
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        onClick={() => handlegptmodel("gpt-3.5-turbo")}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        gpt-3.5-turbo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        onClick={() => handlegptmodel("gpt-3.5-turbo-16k")}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        gpt-3.5-turbo-16k
                      </label>{" "}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        onClick={() => handlegptmodel("gpt-4")}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        gpt-4
                      </label>
                    </div>
                  </div>
                )}
                {excercise_question_list ? (
                  <div>
                    <table className="table m-2 ">
                      <thead>
                        <tr className="">
                          <th>Exercise Number</th>
                          <th>Question No</th>
                          <th>Scores</th>
                          <th>Feedback</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exercisequestionarray.map((item) => {
                          return (
                            <tr>
                              <td>{item.exercise}</td>
                              <td>{item.question}</td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    renamescore(e, item);
                                  }}
                                ></input>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    renamefeedback(e, item);
                                  }}
                                ></input>
                              </td>{" "}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>{" "}
                    <button
                      onClick={(e) => {
                        addoutputscore(e);
                      }}
                      style={{
                        color: "white",
                        backgroundColor: "green",
                        align: "center",
                      }}
                      className={
                        excercise_question_list ? "btn  px-5" : "d-none"
                      }
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="text-center ">
                  <button
                    onClick={() => {
                      if (folderadd === "Input Folder") {
                        handleaddfileinput();
                      } else if (folderadd === "Output Folder") {
                        handleaddfileoutput();
                      }
                    }}
                    style={{ color: "white", backgroundColor: "green" }}
                    className={excercise_question_list ? "d-none" : "btn  px-5"}
                  >
                    {folderadd === "Output Folder"
                      ? "Fetch Exercise and Questions"
                      : "Upload Record"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uploadfile;
