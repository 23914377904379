import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import getNotification from "../Redux/Action/action";
import Sidenav from "./Sidenav";
import UserService from "../services/UserService";
import Select from "react-select";
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_API_KEY } = process.env;

function Allsubmission() {
  const [courseidlist, setcourseidlist] = useState([]);
  const [batchidlist, setbatchidlist] = useState([]);
  const [topicidlist, settopicidlist] = useState([]);
  const [courseId, setcourseId] = useState();
  const [batchId, setbatchId] = useState();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [topicId, settopicId] = useState();
  const [sysprompt, setSysprompt] = useState();
  const [userprompt, setUserpromt] = useState();
  const [gptmodel, setgptmodel] = useState("");
  const [taglist, settaglist] = useState();
  const [coursebatchexam, setcoursebatchexam] = useState("");
  const [newsysprompt, setnewSysprompt] = useState("");
  const [newuserprompt, setnewUserpromt] = useState("");
  const [tagname, settagname] = useState("");
  const [requestId, setRequestId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      fetchcoursebatch();
    } else {
      dispatch(
        getNotification({
          message: "Login has expired.Please login!",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1500);
    }
  }, []);

  const fetchcoursebatch = async () => {
    try {
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_course_and_batchname`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.status === 200) {
        if (getResponse.data === null) {
          dispatch(
            getNotification({
              message: "Files are not added yet by Lawsikho",
              type: "default",
            })
          );
        } else {
          setcoursebatchexam(getResponse.data);
          setcourseidlist(
            getResponse.data.course_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          setbatchidlist(
            getResponse.data.batch_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          settopicidlist(
            getResponse.data.topic_ids.map((it) => {
              return { value: it, label: it };
            })
          );
        }
        //setResponse(getResponse.data.result);
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };

  const handlebatchId = (selection) => {
    if (UserService.isLoggedIn()) {
      setbatchId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };
  const handlecourseId = (selection) => {
    if (UserService.isLoggedIn()) {
      //console.log(value)
      setcourseId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };
  const handletopicId = (selection) => {
    if (UserService.isLoggedIn()) {
      settopicId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };

  const systemprompt = (e) => {
    if (UserService.isLoggedIn()) {
      setSysprompt(e.target.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };
  const handleuserprompt = (e) => {
    if (UserService.isLoggedIn()) {
      setUserpromt(e.target.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };

  const handlegptmodel = (type) => {
    if (UserService.isLoggedIn()) {
      setgptmodel(type);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };

  const fetchpromptsfromtags = (item) => {
    setSysprompt(item.systemprompt);
    setUserpromt(JSON.stringify(item.userprompt));
  };

  const fetchtags = async () => {
    if (UserService.isLoggedIn()) {
      if (batchId && courseId && topicId) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();

          sendData.append("api_key", REACT_APP_API_KEY);
          sendData.append("course_id", courseId);
          sendData.append("batch_id", batchId);
          sendData.append("topic_id", topicId);
          setLoader2(true);
          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/fetch_system_user_tag_prompt`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: sendData,
          };

          const getResponse = await axios(config);
          if (getResponse.status === 200) {
            if (getResponse.data.result[0] === null) {
              dispatch(
                getNotification({
                  message: "There are no saved tags.Please add new tag",
                  type: "default",
                })
              );
            } else {
              settaglist(getResponse.data.result);
            }

            //setResponse(getResponse.data.result);
          }
          //setResponse(getResponse.data.result);
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        alert("Please fill Course Id,Batch Id and Topic Id ");
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
    setLoader2(false);
  };

  const addtag = async () => {
    if (UserService.isLoggedIn()) {
      if (batchId && courseId && topicId && newuserprompt && newsysprompt) {
        if (/\[.*\]/.test(newuserprompt)) {
          try {
            const obj = {
              request: {
                api_key: REACT_APP_API_KEY,
                course_id: courseId,
                batch_id: batchId,
                topic_id: topicId,
                gpt: "gpt-4",
                tag_name: tagname,
                systemprompt: newsysprompt,
              },

              userprompt: JSON.parse(newuserprompt),
            };

            var obj2 = JSON.stringify(obj);
            console.log(obj2);
            const apiUrl = `${REACT_APP_UPLOAD_DATA}/add_tag`;
            const getResponse = await axios.post(apiUrl, obj2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (getResponse.status === 200) {
              alert("Tag is added");
              fetchtags();
              settagname("");
              setnewSysprompt("");
              setnewUserpromt("");

              //setResponse(getResponse.data.result);
            }
          } catch (err) {
            console.log("Server Error", err);
            if (err.response && err.response.status === 500) {
              dispatch(
                getNotification({
                  message: "Kindly check entered format for prompts",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 400) {
              dispatch(
                getNotification({
                  message: "Input is not in prescribed format",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 422) {
              dispatch(
                getNotification({
                  message: "Input is not in prescribed format",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 404) {
              dispatch(
                getNotification({
                  message: "Page not Found",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 401) {
              dispatch(
                getNotification({
                  message: "Session expired! Please log in again",
                  type: "default",
                })
              );
            } else {
              dispatch(
                getNotification({
                  message: "Server is Down! Please try again after sometime",
                  type: "default",
                })
              );
            }
          }
        } else {
          dispatch(
            getNotification({
              message: "Please enter User Prompt in Correct format",
              type: "default",
            })
          );
        }
      } else {
        alert("Please fill all mandatory fields");
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
  };

  const generateoutput = async () => {
    if (UserService.isLoggedIn()) {
      if (
        batchId &&
        courseId &&
        topicId &&
        userprompt &&
        sysprompt &&
        gptmodel
      ) {
        if (/\[.*\]/.test(userprompt)) {
          try {
            setLoader(true);

            const username = await UserService.getFullName();
            const useremail = await UserService.getEmail();
            const request_Id = uuidv4();

            const obj = {
              request: {
                api_key: REACT_APP_API_KEY,
                course_id: courseId,
                batch_id: batchId,
                topic_id: topicId,
                gpt: gptmodel,
                systemprompt: sysprompt,
                tag: tagname,
              },
              mail_request: {
                user_name: username,
                user_email: useremail,
                request_id: request_Id,
              },
              userprompt: JSON.parse(userprompt),
            };

            var obj2 = JSON.stringify(obj);

            const apiUrl = `${REACT_APP_UPLOAD_DATA}/prompt_engine`;
            const getResponse = await axios.post(apiUrl, obj2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (getResponse.status === 200) {
              setRequestId(request_Id);
              alert(
                `Your request is submitted and under process.Request Id: ${request_Id}`
              );

              //setResponse(getResponse.data.result);
            }
          } catch (err) {
            console.log("Server Error", err);
            if (err.response && err.response.status === 500) {
              dispatch(
                getNotification({
                  message: "Server is Down! Please try again after sometime",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 400) {
              dispatch(
                getNotification({
                  message: "Input is not in prescribed format",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 422) {
              dispatch(
                getNotification({
                  message: "Input is not in prescribed format",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 404) {
              dispatch(
                getNotification({
                  message: "Page not Found",
                  type: "default",
                })
              );
            } else if (err.response && err.response.status === 401) {
              dispatch(
                getNotification({
                  message: "Session expired! Please log in again",
                  type: "default",
                })
              );
            } else {
              dispatch(
                getNotification({
                  message: "Server is Down! Please try again after sometime",
                  type: "default",
                })
              );
            }
          }
        } else {
          dispatch(
            getNotification({
              message: "User Prompt should be in correct format",
              type: "default",
            })
          );
        }
      } else {
        alert("Please fill all mandatory fields");
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/allsubmission`,
        });
      }, 1000);
    }
    setLoader(false);
  };

  return (
    <div className=" pageback">
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg " role="document">
          <div className="modal-content ">
            <div
              className="modal-header"
              style={{ backgroundColor: " rgb(237, 248, 248)" }}
            >
              <h5 className="modal-title" id="exampleModalLabel">
                Tag Lists
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {loader2 ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={{ height: "77vh" }}
              >
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Fetching tags.."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            ) : (
              <div className="modal-body">
                {taglist && (
                  <>
                    <div>Click to Auto Enter Prompts</div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Tag Name</th>
                          <th>User Prompt</th>
                          <th>System Prompt</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taglist?.map((item, index) => {
                          return (
                            <tr
                              onClick={() => {
                                fetchpromptsfromtags(item);
                              }}
                              data-dismiss="modal"
                            >
                              <td>{index + 1}</td>
                              <td>{item.tagname}</td>
                              <td>
                                {JSON.stringify(item.userprompt).slice(0, 40)}
                                ...
                              </td>
                              <td>{item.systemprompt.slice(0, 100)}...</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                <h5> Add a new Tag</h5>
                <div>
                  <label htmlFor="textarea">
                    Tag Name <span className="text-danger">*</span>
                  </label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      value={tagname}
                      onChange={(e) => {
                        settagname(e.target.value);
                      }}
                    />
                  </div>
                  <label htmlFor="textarea">
                    Prompts <span className="text-danger">*</span>
                  </label>

                  <div className="mx-4">
                    <label htmlFor="textarea">
                      Please enter User prompt{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control mb-3 mt-1"
                      id="textarea"
                      rows="3"
                      onChange={(e) => {
                        setnewUserpromt(e.target.value);
                      }}
                      placeholder="Please enter User prompt"
                      value={newuserprompt}
                    ></textarea>
                    <label htmlFor="textarea">
                      Please enter System prompt{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control mb-3 mt-1"
                      id="textarea"
                      rows="3"
                      onChange={(e) => setnewSysprompt(e.target.value)}
                      placeholder="Please enter system prompt"
                      value={newsysprompt}
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary float-end"
                    onClick={() => {
                      addtag();
                    }}
                  >
                    Add new Tag
                  </button>
                </div>{" "}
              </div>
            )}
            {/* <div className="modal-footer">

        <button type="button" className="btn btn-success">Close</button>
      </div> */}
          </div>
        </div>
      </div>

      <div className=" " id="main">
        <div className="row ">
          <div className="col-sm-2 sidenav">
            <Sidenav />
          </div>
          <div className=" col-sm-10  page  p-5" id="input">
            <h4 className="text-center" style={{ color: "green" }}>
              All submissions
            </h4>
            {loader ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={{ height: "77vh" }}
              >
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Please wait while we fetch your results.."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            ) : (
              <div className="">
                <div className="form-group lef">
                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Course Id{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      id="courseId"
                      onChange={(value) => handlecourseId(value)}
                      options={courseidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please Enter Course ID"
                      value={courseidlist.filter(
                        (option) => option.value === courseId
                      )}
                    />
                    {/* <option selected disabled>
                        Please provide your Course Id
                      </option>
                      {coursebatchexam.course_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Batch Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="  mb-3"
                      onChange={(value) => handlebatchId(value)}
                      options={batchidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please input Batch Id "
                      value={batchidlist.filter(
                        (option) => option.value === batchId
                      )}
                    />
                    {/* <option selected disabled>
                        Please provide your Batch Id
                      </option>
                      {coursebatchexam.batch_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Topic Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      options={topicidlist}
                      onChange={(value) => handletopicId(value)}
                      aria-describedby="emailHelp"
                      placeholder="Please input Topic Id "
                      value={topicidlist.filter(
                        (option) => option.value === topicId
                      )}
                    />
                    {/* <option selected disabled>
                        Please provide your Topic Id
                      </option>
                      {coursebatchexam.topic_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="textarea">
                    Prompt <span className="text-danger">*</span>
                  </label>

                  <button
                    type="button"
                    onClick={() => fetchtags()}
                    className=" btn bg-white shadow-none no-border float-end"
                    data-toggle={courseId && batchId && topicId ? "modal" : ""}
                    data-target="#exampleModal"
                  >
                    View Previous System Prompts and User Prompts
                  </button>
                  <div className="mx-4">
                    <label htmlFor="textarea">
                      Please enter System prompt{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control mb-3 mt-1"
                      id="textarea"
                      rows="4"
                      onChange={(e) => systemprompt(e)}
                      placeholder="Please enter system prompt"
                      value={sysprompt}
                    ></textarea>
                    <label htmlFor="textarea">
                      Please enter User prompt{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control mb-3 mt-1"
                      id="textarea"
                      rows="4"
                      onChange={(e) => handleuserprompt(e)}
                      placeholder="Please enter User prompt"
                      value={userprompt}
                    ></textarea>
                  </div>

                  <label htmlFor="textarea">
                    Model Type <span className="text-danger">*</span>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={() => handlegptmodel("gpt-3.5-turbo")}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      gpt-3.5-turbo
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => handlegptmodel("gpt-3.5-turbo-16k")}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      gpt-3.5-turbo-16k
                    </label>{" "}
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => handlegptmodel("gpt-4")}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      gpt-4
                    </label>
                  </div>
                </div>
                <div className="text-center ">
                  <button
                    onClick={(e) => {
                      generateoutput();
                    }}
                    style={{ color: "white", backgroundColor: "green" }}
                    className="btn  px-5"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Allsubmission;
