import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import getNotification from "../Redux/Action/action";
import Sidenav from "./Sidenav";
import UserService from "../services/UserService";
import Select from "react-select";
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_REDIRECT_URI } = process.env;
const {REACT_APP_API_KEY}=process.env;
function DownloadRejected() {
  const [courseidlist, setcourseidlist] = useState([]);
  const [batchidlist, setbatchidlist] = useState([]);
  const [topicidlist, settopicidlist] = useState([]);
  const [courseId, setcourseId] = useState();
  // const [question, setquestion] = useState("Can Jallianwala Bagh Massacre be considered as a turning point in our freedom struggle? Give your reasons. ");
  // const [modelAnswer, setmodelAnswer] = useState("A. Does the submission explain the facts related to the jalianwala bagh massacre, the gravity and the emotional impact it had on the people? B. Does the submission explain that the massacre led to disbelief in colonialism. Mistrust in British rule and brought to light that evil inside the British empire? C. Does the submissions mention the beginning of non-cooperation movement, Khilafat movement, civil disobedience and quit india movement? D. Does the submission mention prominent leaders who returned their awards in protest? E. Does the submission mention that the massacre united people and leaders against the Biritsh rule of law? F. Does the submissions explain change in the attitude of Punjab state towards the revolution? G. Does the submission explain the second wave of revolutionary nationalism and mention key leaders like Bhagat singh, Chandrashekhar azad? H. Does the submission explain that it was the change in the attitude of people who actually affected and led the change? I. Is the submission contained within the limit of 150 words?");
  // const [studentAnswer, setstudentAnswer] = useState("Jalianwala Bagh massacre is one of the bloodiest chapters of the Indian National Movement on 13 April 1919, several people (including women and children) were killed at the orders of General Dyre.  What had happened –  People had gathered to celebrate the festival of Baisakhi There were also peaceful discussions on the arrest of two prominent leaders of Punjab -Lala Lajpat rai and Saifuddin Kitchlu. People were also protesting against the oppressive “Rowlett Act” when they were surrounded and shot. Jalianwala Bagh turned out to be a watershed movement in the freedom struggle. Prominent leaders giving up titles topicIdore gave up knighthood Gandhi returned his Kaser-i-hind in protest of the killing. Many who thought negotiations could work, gave up hopes on British Increased mass support/revolts Many mass support movements, demonstrations, dharnas were organized to mobilize people. Emergence of new leaders Like Lala Lajpat Rai, Bhagat Singh got heavily influenced People coming together against British against British oppression (Hindu Muslim unity), Khilafat movement, Non-cooperation     Jalianwala Bagh massacre was an event that turned even the British sympathizers against them. This event marked radical shift in how movements were to be carried out. People got agitated and continuous revolts against British after this. ");

  const [batchId, setbatchId] = useState();
  const [loader, setLoader] = useState(false);
  const [topicId, settopicId] = useState();
  const [sysprompt, setSysprompt] = useState("");
  const [userprompt, setUserpromt] = useState("");
  const [response, setResponse] = useState("");
  const [gptmodel, setgptmodel] = useState("");
  const [filetypeextension, setfiletypeextension] = useState([]);
  const [opscreen, setOpscreen] = useState(false);
  const [docxdownload, setdocxdownload] = useState(false);
  const [exceldownload, setexceldownload] = useState(false);
  const [zipdownload, setzipdownload] = useState(false);
  const [coursebatchexam, setcoursebatchexam] = useState("");
  const [file, setfile] = useState("");
  const dispatch = useDispatch();


  useEffect(() => {
    if (UserService.isLoggedIn()) {
      fetchcoursebatch();
    } else {
      dispatch(
        getNotification({
          message: "Login has expired.Please login!",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/downloadrejectedfiles`,
        });
      }, 1500);
    }
  }, []);

//   const handlefile=(e)=>{
//     console.log(e.target.value);
//     setfile(e.target.value);
//  }
const handlebatchId = (selection) => {
  if (UserService.isLoggedIn()) {
    setbatchId(selection.value);
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/downloadrejectedfiles`,
      });
    }, 1000);
  }
};
const handlecourseId = (selection) => {
  if (UserService.isLoggedIn()) {
    //console.log(value)
    setcourseId(selection.value);
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/downloadrejectedfiles`,
      });
    }, 1000);
  }
};
const handletopicId = (selection) => {
  if (UserService.isLoggedIn()) {
    settopicId(selection.value);
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/downloadrejectedfiles`,
      });
    }, 1000);
  }
};
 
 
  const fetchcoursebatch = async (e) => {
    try {
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_course_and_batchname`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.status === 200) {
        if (getResponse.data === null) {
          dispatch(
            getNotification({
              message: "Files are not added yet by Lawsikho",
              type: "default",
            })
          );
        } else {
          setcoursebatchexam(getResponse.data);
          setcourseidlist(
            getResponse.data.course_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          setbatchidlist(
            getResponse.data.batch_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          settopicidlist(
            getResponse.data.topic_ids.map((it) => {
              return { value: it, label: it };
            })
          );
        }
        //setResponse(getResponse.data.result);
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };

  

const downloadrejected = async () => {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      
      sendData.append("api_key",REACT_APP_API_KEY );
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_unprocessed_files`,
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };

      const getResponse = await axios(config);
    
      if (getResponse.status === 200) {
        //setscore(getResponse.data[0][0].document_score + 1);
        const blob = new Blob([getResponse.data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "unprocessed_files.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(
          getNotification({
            message: "File is downloaded!",
            type: "success",
          })
        );
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }

      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };

 
 

  return (
    <div className=" pageback"> 
    <div className=" " id="main">
      <div className="row ">
      <div className="col-sm-2 sidenav">
<Sidenav/>
        </div>
        <div className=" col-sm-10 page   p-5 " id="input">
          <h4 className="text-center " style={{color:"green"}}>Download Rejected Files</h4>
          {loader ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "77vh" }}
            >
              <Loader
                type="bubble-loop"
                bgColor={"#000000"}
                title={"Please wait while we fetch your results.."}
                color={"#000000"}
                size={100}
              />
            </div>
          ) : (
            <div className="">
              <div className="form-group lef">
               
              <label htmlFor="exampleInputEmail1 mb-2">
                  Please input Course Id <span className="text-danger">*</span>
                </label>
                <div className="mt-1">
                    <Select
                      className="mb-3"
                      id="courseId"
                      onChange={(value) => handlecourseId(value)}
                      options={courseidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please Enter Course ID"
                      value = {
                        courseidlist.filter(option => 
                           option.value === courseId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Course Id
                      </option>
                      {coursebatchexam.course_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Batch Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="  mb-3"
                      onChange={(value) => handlebatchId(value)}
                      options={batchidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please input Batch Id "
                      value = {
                        batchidlist.filter(option => 
                           option.value === batchId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Batch Id
                      </option>
                      {coursebatchexam.batch_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Topic Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      options={topicidlist}
                      onChange={(value) => handletopicId(value)}
                      aria-describedby="emailHelp"
                      placeholder="Please input Topic Id "
                      value = {
                       topicidlist.filter(option => 
                           option.value === topicId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Topic Id
                      </option>
                      {coursebatchexam.topic_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                
                  </div>

             
            
              <div className="text-center ">
                <button
                  onClick={(e) => {
                    downloadrejected(e);
                  }}
                  
                  style={{color:"white",backgroundColor:"green"}}
                  className="btn  px-5"
                >
                  Download
                </button>
              </div>
          </div>  </div>
          )}
        </div>

        {/* {opscreen && (
          <div className="col-sm border p-3" id="output">
     
            <h3 className="text-center ">Results</h3>
            <h6 className="mt-2">
              Download Options: 
            </h6>

     
            <div className="m-3">
              <h6> 1. Input or Output Folder </h6>
              <button
                className="btn btn-secondary btn-sm mx-3"
                onClick={() => {
                  downloadinputoutput();
                }}
              >
                Download Zip File
              </button>{" "}
            </div>

            <div className="m-3">
              <h6>2. Summary File</h6>
              <button
                className="btn btn-secondary btn-sm mx-3"
                onClick={() => downloadsummary()}
              >
                Download Excel File
              </button>{" "}
            </div>
          </div>
        )} */}
      </div>
    </div></div>
   
  );
}

export default DownloadRejected;
