import React from 'react'

function Home() {
  return (
    
     <div className='container-fluid home d-flex align-items-center'>
        <div className='mx-5'> 
        <h1 className='' style={{color:"green"}}>Deepsy</h1>
        <p className='mx-2'>Prompt Engine</p> </div>
       
        </div>    
 
   
  )
}

export default Home