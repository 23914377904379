import React, { useEffect, useState } from "react";
import Navbar2 from "./Navbar";
import axios from "axios";
import UserService from "../services/UserService";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import getNotification from "../Redux/Action/action";
import Sidenav from "./Sidenav";
import Select from "react-select";
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_API_KEY } = process.env;

function Previousprompts() {
  const [courseidlist, setcourseidlist] = useState([]);
  const [batchidlist, setbatchidlist] = useState([]);
  const [topicidlist, settopicidlist] = useState([]);
  const [requestidlist, setrequestidlist] = useState([]);
  const [courseId, setcourseId] = useState();
  const [batchId, setbatchId] = useState();
  const [loader, setLoader] = useState(false);
  const [topicId, settopicId] = useState();
  const [requestId, setrequestId] = useState();
  const [opscreen1, setopscreen1] = useState(false);
  const [opscreen2, setopscreen2] = useState(false);
  const [coursebatchexam, setcoursebatchexam] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      fetchcoursebatch();
    } else {
      dispatch(
        getNotification({
          message: "Login has expired.Please login!",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1500);
    }
  }, []);
  const handlebatchId = (selection) => {
    if (UserService.isLoggedIn()) {
      setbatchId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1000);
    }
  };
  const handlecourseId = (selection) => {
    if (UserService.isLoggedIn()) {
      //console.log(value)
      setcourseId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1000);
    }
  };
  const handletopicId = (selection) => {
    if (UserService.isLoggedIn()) {
      settopicId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1000);
    }
  };

  const handlerequestId = (selection) => {
    if (UserService.isLoggedIn()) {
      setrequestId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1000);
    }
  };
  const fetchcoursebatch = async (e) => {
    try {
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_course_and_batchname_if_processed`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const getResponse = await axios(config);

      if (getResponse.status === 200) {
        if (getResponse.data.message) {
          dispatch(
            getNotification({
              message: "Files are not processed yet",
              type: "default",
            })
          );
        } else {
          setcoursebatchexam(getResponse.data);
          setcourseidlist(
            getResponse.data.course_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          setbatchidlist(
            getResponse.data.batch_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          settopicidlist(
            getResponse.data.topic_ids.map((it) => {
              return { value: it, label: it };
            })
          );
        }
        //setResponse(getResponse.data.result);
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };
  const fetchsummary = () => {
    if (UserService.isLoggedIn()) {
      if (courseId && batchId && topicId && requestId) {
        setopscreen1(true);
      } else {
        dispatch(
          getNotification({
            message: "Please fill all fields",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/previousprompts`,
        });
      }, 1000);
    }
  };

  const downloadinputoutput = async () => {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("api_key", REACT_APP_API_KEY);
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
      sendData.append("request_id", requestId);

      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/download_input_and_output/`,
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };

      const getResponse = await axios(config);

      if (getResponse.status === 200) {
        //setscore(getResponse.data[0][0].document_score + 1);
        const blob = new Blob([getResponse.data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "inputoutput.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }

      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };

  const downloadsummary = async () => {
    //console.log("d00")

    try {
      const FormData = require("form-data");
      const sendData = new FormData();

      sendData.append("api_key", REACT_APP_API_KEY);
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
      sendData.append("request_id", requestId);
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/download_summary/`,
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: sendData,
      };

      const getResponse = await axios(config);

      if (getResponse.status === 200) {
        //setscore(getResponse.data[0][0].document_score + 1);
        const blob = new Blob([getResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "summary.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }

      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };
  const fetchrequestids = async () => {
    //console.log("d00")
  
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
  
      sendData.append("api_key", REACT_APP_API_KEY);
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
  
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_request_id_prompt_engine/`,
  
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
  
      const getResponse = await axios(config);
      //console.log(getResponse);
      if (getResponse.status === 200) {
              //setscore(getResponse.data[0][0].document_score + 1);
              if(typeof(getResponse.data.request_id_array)==="string"){
                dispatch(
                  getNotification({
                    message:"There are no processed request ids currently",
                    type:"default"
                  })
                )
              }
                else{
                setopscreen2(true);
        setrequestidlist(getResponse.data.request_id_array.map((it) => {
              return { value: it, label: it };
            }));    
                }
      
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }
  
      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };

  const failedsummary = async () => {
    //console.log("d00")

    try {
      const FormData = require("form-data");
      const sendData = new FormData();

      sendData.append("api_key", REACT_APP_API_KEY);
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
      sendData.append("request_id", requestId);
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/failed_details/`,
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };

      const getResponse = await axios(config);
      if (getResponse.status === 200) {
        //setscore(getResponse.data[0][0].document_score + 1);
        const blob = new Blob([getResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "failedrecords.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }

      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };
  const completeprocesseddetails = async () => {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();

      sendData.append("api_key", REACT_APP_API_KEY);
      sendData.append("course_id", courseId);
      sendData.append("batch_id", batchId);
      sendData.append("topic_id", topicId);
      sendData.append("request_id", requestId);
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/complete_processed_details/`,
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };

      const getResponse = await axios(config);

      if (getResponse.status === 200) {
        //setscore(getResponse.data[0][0].document_score + 1);
        const blob = new Blob([getResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "completeprocesseddetails.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (getResponse.status === 422) {
        dispatch(
          getNotification({
            message: "The source entered is not in prescribed format",
            type: "danger",
          })
        );
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is down.Please try after sometime",
            type: "danger",
          })
        );
      }

      // setTimeout(()=>{
      //   navigate("/ErrorPage")
      // },1000)
    }
  };

  return (
    <div className=" pageback">
      <div className=" " id="main">
        <div className="row ">
          <div className="col-sm-2 sidenav">
            <Sidenav />
          </div>
          <div className=" col-sm-10 p-5 page" id="input">
            <h4 className="text-center" style={{ color: "green" }}>
              {" "}
              Previous Prompts Output
            </h4>
            {loader ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={{ height: "77vh" }}
              >
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Please wait while we fetch your results.."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            ) : (
              <div>
                <div className="form-group lef">
                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Course Id{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      id="courseId"
                      onChange={(value) => handlecourseId(value)}
                      options={courseidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please Enter Course ID"
                      value={courseidlist.filter(
                        (option) => option.value === courseId
                      )}
                    />
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Batch Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="  mb-3"
                      onChange={(value) => handlebatchId(value)}
                      options={batchidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please input Batch Id "
                      value={batchidlist.filter(
                        (option) => option.value === batchId
                      )}
                    />
                    {/* <option selected disabled>
                        Please provide your Batch Id
                      </option>
                      {coursebatchexam.batch_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Topic Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      options={topicidlist}
                      onChange={(value) => handletopicId(value)}
                      aria-describedby="emailHelp"
                      placeholder="Please input Topic Id "
                      value={topicidlist.filter(
                        (option) => option.value === topicId
                      )}
                    />
                  </div>
                  {opscreen2 ? (
                    <>
                      {" "}
                      <label htmlFor="exampleInputEmail1 mb-2">
                        Please input Request Id{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="mt-1">
                        <Select
                          className="mb-3"
                          options={requestidlist}
                          onChange={(value) => handlerequestId(value)}
                          aria-describedby="requestids"
                          placeholder="Please input Request Id "
                          value={requestidlist?.filter(
                            (option) => option.value === requestId
                          )}
                        />
                      </div>
                      <div className="text-center ">
                        <button
                          onClick={(e) => {
                            fetchsummary(e);
                          }}
                          className="btn px-5"
                          style={{ color: "white", backgroundColor: "green" }}
                        >
                          Fetch Results
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="text-center ">
                      <button
                        onClick={(e) => {
                          fetchrequestids(e);
                        }}
                        className="btn px-5"
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        Fetch Request Ids
                      </button>
                    </div>
                  )}
                </div>

                {opscreen1 && (
                  <div>
                    <h4 className=" ">Results</h4>
                    <h6 className="mt-2">Download Options:</h6>

                    <div className="m-3">
                      <h6> 1. Input or Output Folder </h6>
                      <button
                        className="btn btn-secondary btn-sm mx-3"
                        onClick={() => {
                          downloadinputoutput();
                        }}
                      >
                        Download Zip File
                      </button>{" "}
                    </div>

                    <div className="m-3">
                      <h6>2. Summary </h6>
                      <button
                        className="btn btn-secondary btn-sm mx-3"
                        onClick={() => downloadsummary()}
                      >
                        Download Excel File
                      </button>{" "}
                    </div>
                    <div className="m-3">
                      <h6>3. Failed Records</h6>
                      <button
                        className="btn btn-secondary btn-sm mx-3"
                        onClick={() => failedsummary()}
                      >
                        Download Excel File
                      </button>{" "}
                    </div>
                    <div className="m-3">
                      <h6>4.Complete Processed Details</h6>
                      <button
                        className="btn btn-secondary btn-sm mx-3"
                        onClick={() => completeprocesseddetails()}
                      >
                        Download Excel File
                      </button>{" "}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Previousprompts;
