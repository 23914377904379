import React, { useEffect, useState } from "react";
import UserService from "../services/UserService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Sidenav from "./Sidenav";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import getNotification from "../Redux/Action/action";
import Select from "react-select";
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_REDIRECT_URI } = process.env;
const {REACT_APP_API_KEY}=process.env;

function Deletefile() {
  const [courseidlist, setcourseidlist] = useState([]);
  const [batchidlist, setbatchidlist] = useState([]);
  const [topicidlist, settopicidlist] = useState([]);
  const [courseId, setcourseId] = useState();
  const [batchId, setbatchId] = useState();
  const [loader, setLoader] = useState(false);
  const [topicId, settopicId] = useState();
  const [subid,setsubid]=useState();
  const [response, setResponse] = useState("");
  const [folderdelete, setfolderdelete] = useState("");
   const [coursebatchexam, setcoursebatchexam] = useState("");
  const dispatch = useDispatch();


  useEffect(() => {
    if (UserService.isLoggedIn()) {
      fetchcoursebatch();
    } else {
      dispatch(
        getNotification({
          message: "Login has expired.Please login!",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1500);
    }
  }, []);

  const handlebatchId = (selection) => {
    if (UserService.isLoggedIn()) {
      setbatchId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
  };
  const handlecourseId = (selection) => {
    if (UserService.isLoggedIn()) {
      //console.log(value)
      setcourseId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
  };
  const handletopicId = (selection) => {
    if (UserService.isLoggedIn()) {
      settopicId(selection.value);
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
  };
  const handlesubid = (e) => {
    if (UserService.isLoggedIn()) {
      setsubid(e.target.value);
     
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
  };

 
  const handlefolder = (type) => {
    if (UserService.isLoggedIn()) {
      
      setfolderdelete(type);
     
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
  };
  const fetchcoursebatch = async (e) => {
    try {
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/fetch_course_and_batchname`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.status === 200) {
        if (getResponse.data === null) {
          dispatch(
            getNotification({
              message: "Files are not added yet by Lawsikho",
              type: "default",
            })
          );
        } else {
          setcoursebatchexam(getResponse.data);
          setcourseidlist(
            getResponse.data.course_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          setbatchidlist(
            getResponse.data.batch_ids.map((it) => {
              return { value: it, label: it };
            })
          );
          settopicidlist(
            getResponse.data.topic_ids.map((it) => {
              return { value: it, label: it };
            })
          );
        }
        //setResponse(getResponse.data.result);
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };
  const deletefile = async () => {
    if (UserService.isLoggedIn()) {
      
     
      if (batchId && courseId && topicId && subid && folderdelete) { 
        try {
        const FormData = require("form-data");
        const sendData = new FormData();
        
        sendData.append("api_key",REACT_APP_API_KEY);
        sendData.append("course_id", courseId);
        sendData.append("batch_id", batchId);
        sendData.append("topic_id", topicId);
        sendData.append("sub_id", subid);
        var config={};
     
    if(folderdelete==="Input Folder"){
    config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/delete_input_file/`,
          headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
    }
    else if(folderdelete==="Output Folder"){
       config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/delete_output_file/`,
          headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
    }
  
       setLoader(true)
        const getResponse = await axios(config);
     
        if (getResponse.status === 200) {
          //setscore(getResponse.data[0][0].document_score + 1);
          alert("Your file is deleted ");
          // dispatch(
          //     getNotification({
          //       message: "File is deleted",
          //       type: "Success",
          //     })
          //   );
        } else if (getResponse.status === 422) {
          dispatch(
            getNotification({
              message: "The source entered is not in prescribed format",
              type: "danger",
            })
          );
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is down.Please try after sometime",
              type: "danger",
            })
          );
        }
  
   
      }}
     
      else{   alert("Please fill all mandatory fields");
  }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/deletefile`,
        });
      }, 1000);
    }
setLoader(false);
  };


  return (
    <div className=" pageback"> 
    <div className="" id="main">
      <div className="row">
      <div className="col-sm-2 sidenav">
<Sidenav/>
        </div>
        <div className=" col-sm-10 page  p-5" id="input">
          <h4 className="text-center"  style={{color:"green"}}>Delete Files</h4>
          {loader ? (
            <div
              className="d-flex  justify-content-center align-items-center"
              style={{ height: "77vh" }}
            >
              <Loader
                type="bubble-loop"
                bgColor={"#000000"}
                title={"Please wait while we fetch your results.."}
                color={"#000000"}
                size={100}
              />
            </div>
          ) : (
            <div className="">
              <div className="form-group lef">
                <label htmlFor="exampleInputEmail1 mb-2">
                  Please input Course Id <span className="text-danger">*</span>
                </label>
             <div className="mt-1">
                    <Select
                      className="mb-3"
                      id="courseId"
                      onChange={(value) => handlecourseId(value)}
                      options={courseidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please Enter Course ID"
                      value = {
                        courseidlist.filter(option => 
                           option.value === courseId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Course Id
                      </option>
                      {coursebatchexam.course_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Batch Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="  mb-3"
                      onChange={(value) => handlebatchId(value)}
                      options={batchidlist}
                      aria-describedby="emailHelp"
                      placeholder="Please input Batch Id "
                      value = {
                        batchidlist.filter(option => 
                           option.value === batchId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Batch Id
                      </option>
                      {coursebatchexam.batch_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>

                  <label htmlFor="exampleInputEmail1 mb-2">
                    Please input Topic Id <span className="text-danger">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      className="mb-3"
                      options={topicidlist}
                      onChange={(value) => handletopicId(value)}
                      aria-describedby="emailHelp"
                      placeholder="Please input Topic Id "
                      value = {
                       topicidlist.filter(option => 
                           option.value === topicId)
                     }
                    />
                    {/* <option selected disabled>
                        Please provide your Topic Id
                      </option>
                      {coursebatchexam.topic_ids?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select> */}
                  </div>
                <label htmlFor="exampleInputEmail1 mb-2">
                  Please input Sub Id <span className="text-danger">*</span>
                </label>
                <input
                type="text"
                className="form-control  mb-3"
                value={subid}
                onChange={(e) => handlesubid(e)}
                aria-describedby="emailHelp"
                placeholder="Please Enter Sub ID"
              />

          </div>
          <label htmlFor="textarea">
                 Folder to be deleted <span className="text-danger">*</span>
                </label>
                <div className="form-check">
                 
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={() => handlefolder("Input Folder")}
                  />
                   <label className="form-check-label" for="flexRadioDefault1">
                  Input Folder
                  </label>
                </div>
                <div className="form-check">
               
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={() => handlefolder("Output Folder")}
                  />
                     <label className="form-check-label" for="flexRadioDefault1">
                  Output Folder
                  </label>
                </div>
              <div className="text-center ">
                <button
                  onClick={() => {
                    deletefile();
                  }}
                  style={{color:"white",backgroundColor:"green"}}
                  
                  className="btn  px-5"
                >
                Delete Record
                </button>
              </div>
            </div>
          )}
        </div>

       

      </div>
    </div>
    </div>
  );
}

export default Deletefile;
